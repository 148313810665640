<template>
    <div class="inquiry_popup modal1">
        <div class="music_report modal_box">
            <div class="music_report_title modal_title">
                <h2>신고하기</h2>
                <div class="modal_close_btn" @click="closeModal()">
                    <img src="/media/images/icon_close_24.svg" alt="" />
                </div>
                <div class="mo_space_header">
                    <div class="mo_header_box">
                        <h4 style="margin-bottom:0px;">신고하기</h4>
                        <div class="btn_close">
                            <img src="/media/images/icon_close_24.svg" @click="closeModal()">
                        </div>
                    </div>
                </div>
            </div>
            <div class="music_report_content">
                <ul>
                    <li>
                        <div>
                            <label for="report_content1" @click="selectReportContent(0, $event)">
                                <span class="check" :class="{ on : selectItem[0] }"></span>
                                곡명/가수명 오류
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            <label for="report_content2" @click="selectReportContent(1, $event)">
                                <span class="check" :class="{ on : selectItem[1] }"></span>
                                장르 오류
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            <label for="report_content3" @click="selectReportContent(2, $event)">
                                <span class="check" :class="{ on : selectItem[2] }"></span>
                                앨범 이미지 오류
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            <label for="report_content" @click="selectReportContent(3, $event)">
                                <span class="check" :class="{ on : selectItem[3] }"></span>
                                노래 끊김
                            </label>
                        </div>
                    </li>
                    <li>
                        <div>
                            <label for="report_content4" @click="selectReportContent(4, $event)">
                                <span class="check" :class="{ on : selectItem[4] }"></span>
                                기타
                            </label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="report_content" :class="{ on: disabledEtcContent}">
                <textarea
                    type="text"
                    maxlength="50"
                    placeholder="오류 내용을 직접 입력해주세요.(최대 50자)"
                    v-model="etcContent"
                    @keyup="keyEvent()"
                ></textarea>
                <p>{{ etcContent.length }}<span>/50</span></p>
            </div>
            <div class="popup_bottom modal_bottom_btn">
                <button class="popup_out" @click="$emit('closeModal2')">취소</button>
                <button :class="{ disabled: disabledInsert === true }" v-if="!disabledButton" @click="insertMusicReport()">신고</button>
                <button style="text-align:center;filter: brightness(30%);" v-else>신고</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";

export default defineComponent({
  name: 'MusicReport',
  data () {
    return {
      musicPath: '',
      content: [],
      etcContent: "",
      disabledEtcContent: false,
      disabledInsert: false,
      disabledButton: true,
      selectItem: [false, false, false, false, false]
    };
  },
  props: {
    musicId: String
  },
  mounted () {
    this.musicPath = this.musicId;
  },
  methods: {
    closeModal () {
      this.selectItem = [false, false, false, false, false];
      this.$emit('closeModal2');
    },
    keyEvent () {
      // 신고 내용을 기타만 선택하고 오류 내용 입력 유무에 따라 신고버튼 on/off
      this.content.length === 1 && this.selectItem[4] && this.etcContent.length === 0 ? this.disabledButton = true : this.disabledButton = false;
    },
    insertMusicReport () {
      this.disabledInsert = true;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      let musicId = null;
      this.musicPath.indexOf('music_') !== -1 ? musicId = this.musicPath : musicId = this.musicPath.substring(17);
      const userId = VueCookies.get("userId");
      // content 값 정의
      let content = '';
      // 기타의 index값 찾기
      const index = this.content.findIndex(el => el === ' 기타 ');
      // 기타를 선택한 경우 기타제거 및 입력한 오류내용 content에 넣기
      if (index !== -1) this.content.splice(index, 1, this.etcContent).toString();
      // content값 String타입으로 변환
      content = this.content.toString();

      axios
        .post("/api/player/insertMusicReport", { musicId, userId, content }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.selectItem = [false, false, false, false, false];
            this.disabledInsert = false;
            this.$emit('closeModal');
          }
        })
        .catch(err => {
          this.disabledInsert = false;
          console.log(err);
        });
    },
    selectReportContent (index, e) {
      // 오류값 선택
      var content = e.currentTarget.textContent;
      this.selectItem[index] ? this.selectItem[index] = false : this.selectItem[index] = true;
      this.selectItem[index] ? this.content.push(content) : this.content = this.content.filter((el) => el !== content);
      this.selectItem.push();
      // 기타 선택 시 오류 내용 입력창 on/off
      this.selectItem[4] ? this.disabledEtcContent = true : this.disabledEtcContent = false;
      // 신고 내용 선택값 유무에 따른 신고버튼 on/off
      this.content.length === 0 ? this.disabledButton = true
        : this.content.length === 1 && this.selectItem[4] && this.etcContent.length === 0 ? this.disabledButton = true : this.disabledButton = false;
    }
  }
});
</script>

<style scoped>
  span.check {display: inline-block; width: 24px; height: 24px; background:url('/media/img/login_check_off.png') no-repeat; background-size: 100%; cursor: pointer;}
  span.check.on {background:url('/media/img/login_check_active.png') no-repeat !important; background-size: 100%; border: none !important;}
  .disabled { pointer-events: none; }
  .modal_bottom_btn button {
    margin-bottom: 0;
  }
  @media all and (max-width: 500px) {
    .modal_bottom_btn button {
      margin-bottom: 0;
    }
    .modal_close_btn {
      display: none;
    }
  }
  @media all and (min-width: 501px) {
    .mo_space_header {
      display: none;
    }
  }
</style>
